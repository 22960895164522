<template>

  <BlockUI :blocked="loading>1">

    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <h3><i class="pi pi-comments" style="font-size: 1.6rem"></i> Ticket {{ (ticket != null)?'#' + ticket.id:'' }}</h3>
      </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12">
            <Panel>
                <template #header>
                    <strong>Detalhes do Ticket</strong>
                </template>
                <div class="p-grid" v-if="ticket != null">
                    <div class="p-col-3">
                        <div class="p-mb-2"><strong>Criador:</strong></div>
                        <div>{{ ticket.criador_item.name }} ({{ (!ticket.criador_item.imobiliaria)?'Corretora':ticket.criador_item.imobiliaria_item.nome }})</div>
                    </div>
                    <div class="p-col-3">
                        <div class="p-mb-2"><strong>Data de Criação:</strong></div>
                        <div>{{ $utils.formatDateTimeToBr(ticket.data) }}</div>
                    </div>
                    <div class="p-col-3">
                        <div class="p-mb-2"><strong>Última Interação:</strong></div>
                        <div>{{ $utils.formatDateTimeToBr(ticket.atual_interacao_data) }}</div>
                    </div>
                    <div class="p-col-3">
                        <div class="p-mb-2"><strong>Status:</strong></div>
                        <div>
                          <Tag 
                            v-if="$auth.currentUser.id == ticket.atual_remetente"
                            :value="status[ticket.atual_status_remetente].label"
                            :icon="status[ticket.atual_status_remetente].icon" 
                            :severity="status[ticket.atual_status_remetente].severity">
                          </Tag>
                          <Tag 
                            v-else
                            :value="status[ticket.atual_status_destinatario].label"
                            :icon="status[ticket.atual_status_destinatario].icon" 
                            :severity="status[ticket.atual_status_destinatario].severity">
                          </Tag>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-mb-2"><strong>Assunto:</strong></div>
                        <div>{{ ticket.assunto }}</div>
                    </div>

                    <div class="p-col-6">
                        <div class="p-mb-2"><strong>Análise Relacionada:</strong></div>
                        <div v-if="ticket.processo">
                            <router-link :to="{ name: 'processo', params: { 'id': ticket.processo }}">
                                {{ 'Negócio: ' + ticket.processo_item.id + ' (' + ticket.processo_item.imovel_item.id_proprio + ' - ' + ticket.processo_item.locatario_item.nome + ')' }}
                            </router-link>
                        </div>
                        <div v-else>
                          Nenhum.
                        </div>
                    </div>

                    <div class="p-col-12">
                        <div class="p-mb-2"><strong>Mensagem:</strong></div>
                        <div>{{ ticket.mensagem }}</div>
                    </div>

                    <div class="p-col-12" v-if="corretora && ticket.mensagem_corretora">
                        <div class="p-mb-2"><strong>Mensagem Interna (Corretora):</strong></div>
                        <div>{{ ticket.mensagem_corretora }}</div>
                    </div>

                    <div class="p-col-12" v-if="ticket.arquivos.length > 0">
                        <div class="p-mb-2"><strong>Anexos:</strong></div>
                        <span v-for="a of ticket.arquivos" :key=a.id>
                            <Button :label="a.nome" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="anexo_abrir(a)"></Button>
                        </span>
                    </div>


                </div>
            </Panel>
        </div>

        <div class="p-col-12">

            <Accordion>
                <AccordionTab>
                    <template #header>
                        <span class="p-mr-2">Responder Ticket</span>
                    </template>

                    <div class="p-grid p-fluid">

                        <div class="p-col-12 p-md-6 p-field" v-if="corretora">
                          <label for="destinatario">Destinatário (opcional)</label>
                          <div class="p-inputgroup">
                            <AutoComplete
                              id="destinatario"
                              v-model="destinatario"
                              :suggestions="destinatarioResultados"
                              @complete="destinatarioFiltrarAutoComplete($event)"
                              @blur="validaAutoComplete('destinatario')"
                              field="label"
                              optionGroupLabel="label"
                              optionGroupChildren="items"
                              placeholder="Informe parte do nome"
                              :class="
                                fieldsError.some((v) => v === 'destinatario') ? 'p-invalid' : ''
                              "
                            />
                          </div>
                        </div>

                        <div class="p-col-12 p-md-6 p-field" v-if="mostrar_finalizacao">
                          <label for="novo_status" class="p-d-block">Finalizar Ticket?</label>
                          <div class="p-pt-1">
                            <Checkbox id="novo_status" value="CONCLUIDO" v-model="novo_status" /> <span class="p-ml-1">CONCLUÍDO</span>
                          </div>
                        </div>

                        <div class="p-field p-col-12">
                          <label for="mensagem">Mensagem</label>
                          <Textarea 
                          :autoResize="true"
                          id="mensagem"
                          v-model="mensagem"
                          :class="(fieldsError.some(v => v === 'mensagem'))?'p-invalid':''"
                          rows="5" cols="30"
                          />
                        </div>

                        <div class="p-field p-col-12" v-if="corretora">
                          <label for="mensagem_corretora">Mensagem Interna (Corretora)</label>
                          <Textarea 
                          :autoResize="true"
                          id="mensagem_corretora"
                          v-model="mensagem_corretora"
                          :class="(fieldsError.some(v => v === 'mensagem_corretora'))?'p-invalid':''"
                          rows="1" cols="30"
                          />
                        </div>

                        <div class="p-col-12">
                            <FileUpload 
                                chooseLabel="Anexar Arquivos" 
                                mode="basic" 
                                name="upload[]" 
                                :url="$api.url() + '/arquivos'"
                                :auto="true" 
                                :multiple="true"
                                :maxFileSize="50000000"
                                accept="image/*,video/*,application/pdf,application/zip"
                                @before-send="upload_headers"
                                @upload="upload_finalizado"
                                @progress="upload_progress"
                                @error="upload_error"
                            >
                            </FileUpload>
                        </div>
                        <div class="p-col-12" v-if="progress_upload > 0 && progress_upload < 100">
                            <ProgressBar :value="progress_upload"></ProgressBar>
                        </div>
                        <div class="p-col-12" v-if="arquivos.length > 0">
                            <span v-for="a of arquivos" :key=a.id>
                                <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a)"></Button>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <Button label="Enviar Resposta" class="p-button-lg p-button-success" style="width: auto" icon="pi pi-check" @click="responder"></Button>
                        </div>
                    </div>

                </AccordionTab>
            </Accordion>

        </div>

        <div class="p-col-12">
            <Panel>
                <template #header>
                    <strong>Interações do Ticket</strong>
                </template>
                <div class="p-grid">
                    <div class="p-col-12" v-if="ticket == null || ticket.interacoes.length == 0">
                        Nenhuma interação registrada.
                    </div>
                    <div class="p-col-12 interacoes" v-else>
                        <div class="p-grid interacao" v-for="interacao of ticket.interacoes" :key="interacao.id">
                            <div class="p-col-3">
                                <div class="p-mb-2"><strong>Data da Resposta:</strong></div>
                                {{ $utils.formatDateTimeToBr(interacao.data) }}
                            </div>
                            <div class="p-col-3">
                                <div class="p-mb-2"><strong>Respondido Por:</strong></div>
                                {{ interacao.remetente_item.name }} ({{ (!interacao.remetente_item.imobiliaria)?'Corretora':interacao.remetente_item.imobiliaria_item.nome }})
                            </div>
                            <div class="p-col-3">
                                <div class="p-mb-2"><strong>Direcionado à:</strong></div>
                                <div v-if="interacao.destinatario">
                                {{ interacao.destinatario_item.name }} ({{ (!interacao.destinatario_item.imobiliaria)?'Corretora':interacao.destinatario_item.imobiliaria_item.nome }})
                                </div>
                                <div v-else>
                                {{ interacao.destinatario_imobiliaria_item.nome }} (Imobiliária)
                                </div>

                            </div>
                            <div class="p-col-12">
                                <div class="p-mb-2"><strong>Mensagem:</strong></div>
                                {{ interacao.mensagem }}
                            </div>
                            <div class="p-col-12" v-if="corretora && interacao.mensagem_corretora">
                                <div class="p-mb-2"><strong>Mensagem Interna (Corretora):</strong></div>
                                {{ interacao.mensagem_corretora }}
                            </div>
                            <div class="p-col-12" v-if="interacao.arquivos.length > 0">
                                <div class="p-mb-2"><strong>Anexos:</strong></div>
                                <span v-for="a of interacao.arquivos" :key=a.id>
                                    <Button :label="a.nome" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="anexo_abrir(a)"></Button>
                                </span>
                            </div>

                        </div>

                    </div>
                </div>
            </Panel>
        </div>

    </div>

    <div class="center-loading" v-if="loading>1">
        <ProgressSpinner />
    </div>

  </BlockUI>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>

export default {
  data() {
    return {

      // AUTOCOMPLETE OBRIGATORIOS: //
      destinatarioResultados: [],

      // 1. Controle de apresentação
      loading: 0,
      progress_upload: 0,
      mostrar_finalizacao: false,

      // 1.1. Validadores
      fieldsError: [],

      // 2. Campos do Formulário
      arquivos : [],
      mensagem: "",
      mensagem_corretora: "",
      destinatario: null,
      novo_status: null,

      // 3. Objeto em edição
      ticket: null,

      // 4. Chaves Estrangeiras
      status: {
        'NOVO' : {
          id: 'NOVO',
          label: 'NOVO',
          severity: "info",
          icon: "pi pi-clock",
        },
        'RESPONDIDO' : {
          id: 'RESPONDIDO',
          label: 'RESPONDIDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'CONCLUIDO': {
          id: 'CONCLUIDO',
          label: 'CONCLUÍDO',
          severity: "success",
          icon: "pi pi-check",
        },
        'ARQUIVADO': {
          id: 'ARQUIVADO',
          label: 'ARQUIVADO',
          severity: "danger",
          icon: "pi pi-times",
        },
      },

      corretora: false

    };
  },

  mounted() {

    if(this.$auth.currentUser.imobiliaria == null) {
      this.corretora = true;
    }
    
    this.carregarObjetoRota();

  },

  watch: {

    ticket: function(/*val*/) {
      if(this.$auth.currentUser.imobiliaria == null) { //val && val.criador == this.$auth.currentUser.id || 
        this.mostrar_finalizacao = true;
      } else {
        this.mostrar_finalizacao = false;
      }
    },

    $route() {
      if(this.$route.name == "ticket")
        this.carregarObjetoRota();
    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {
        const self = this;

        self.loading++;

        if (typeof this.$route.params.id != "string" || this.$route.params.id == "") {
            self.loading--;
            return;
        }

        this.$api
        .get("/tickets/" + this.$route.params.id)
        .then(function (response) {
            const dados = response.data;
            if (dados.success) { 
                self.ticket = dados.data;
            } else {
                alert(dados.message);
                self.$router.push({ path: `/tickets` });
            }
            self.loading--;
        }).catch(function (response) {
          const data = response.response.data;
          
          self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: data.message,
              life: 5000,
          });

          self.$router.push({ path: `/tickets` });

        });

    },

    validaAutoComplete(val) {
      if(typeof this[val] != "object")
        this[val] = null;
    },

    destinatarioFiltrarAutoComplete(event) {

      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get("/tickets/destinatarios?str=" + q)
        .then(function (response) {
          const dados = response.data;
          if (dados.success) {

            self.destinatarioResultados = dados.data;

          }
        });

    },

    limparFormulario() {
      //Limpa as variáveis
    
      // 2. Campos do formulário
      this.mensagem = "";
      this.mensagem_corretora = "";
      this.destinatario = null;
      this.novo_status = null;

      for(const a of this.arquivos) {
        this.$api.post("/arquivos/" + a.id, {"_method" : "DELETE"});
      }

      this.arquivos = [];

    },

    upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    upload_progress(event) {
        this.progress_upload = event.progress;
    },

    upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    upload_click(event, file) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(self.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                        const idx = self.arquivos.findIndex(e => e.id == file.id);
                        self.arquivos.splice(idx, 1);

                        self.$toast.add({
                            severity: (response.data.success)?"success":"error",
                            summary: (response.data.success)?"Sucesso!":"ERRO!",
                            detail: response.data.message,
                            life: (response.data.success)?3000:5000,
                        });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    upload_finalizado(event) {
        const res = JSON.parse(event.xhr.response);
        this.arquivos = this.arquivos.concat(res.data);
    },

    // // // AÇÕES DO USUÁRIO // // //

    anexo_abrir(arquivo) {
        window.open(this.$api.url() + '/arquivos/' + arquivo.nome, '_blank');
    },

    responder() {
      const self = this;

      this.fieldsError = [];

      let dados = {
        "mensagem": this.mensagem,
        "mensagem_corretora": this.mensagem_corretora,
        "status": (this.novo_status != null && this.novo_status.length > 0) ? this.novo_status[0] : null
      };

      if(this.destinatario)
        dados[this.destinatario.field] = this.destinatario.id;

      if(this.arquivos.length > 0) {
        dados['arquivos'] = [];
        for(const a of this.arquivos) {
          dados['arquivos'].push(a.id);
        }
      }

      this.$api
        .post(
          "/tickets/" + this.ticket.id + "/responder",
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.arquivos = [];

          self.limparFormulario();

          self.$router.push({ path: `/tickets` });

          // self.carregarObjetoRota();

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {             
              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    },

  },
};
</script>

<style scoped>

  :deep(.interacao) {
    border-bottom: 1px solid #8b8b8b;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }

  :deep(.interacoes .interacao:last-child) {
    margin-bottom: 0;
    border: 0;
    padding-bottom: 0;
  }

</style>